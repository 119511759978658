import axios from "../utils/axios.instance";
import api from "../api";
import handleError from "./handle-error";

export const getAccountDetails = async () => {
  try {
    const { data } = await axios.get(api.getAccountDetails);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getStripeAccountDetails = async (accountId) => {
  try {
    const { data } = await axios.get(
      `${api.getStripeAccountDetails}/${accountId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const createStripeAccountSession = async (accountId) => {
  try {
    const { data } = await axios.post(api.createStripeAccountSession, {
      accountId,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const createCheckoutSession = async (accountId, amount) => {
  try {
    const { data } = await axios.post(api.createCheckoutSession, {
      accountId,
      amount,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getReferrals = async () => {
  try {
    const { data } = await axios.get(api.getReferrals);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const createPaymentIntent = async (accountId, amount) => {
  try {
    const { data } = await axios.post(api.createPaymentIntent, {
      accountId,
      amount,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addCardPaymentMethod = async (token) => {
  try {
    const { data } = await axios.post(api.addCardPaymentMethod, {
      token,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addBankDetails = async (token) => {
  try {
    const { data } = await axios.post(api.addBankDetails, {
      token,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getBanksLists = async () => {
  try {
    const { data } = await axios.get(api.getBankLists);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getBankDetails = async (bankId) => {
  try {
    const { data } = await axios.get(`${api.fetchBankDetails}/${bankId}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateBankDetails = async (bankId, name) => {
  try {
    const { data } = await axios.post(api.updateBankDetails, {
      bankId,
      name,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const makeBankDefault = async (bankId) => {
  try {
    const { data } = await axios.post(api.makeDefaultBank, {
      bankId,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteBank = async (bankId) => {
  try {
    const { data } = await axios.delete(`${api.deleteBank}/${bankId}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getBalanceInfo = async () => {
  try {
    const { data } = await axios.get(api.getBalanceDetails);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const withDrawBalance = async (amount) => {
  try {
    const { data } = await axios.post(api.withDrawBalance, {
      amount,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getPaymentMethods = async (type) => {
  try {
    const { data } = await axios.get(`${api.fetchPaymentMethods}/${type}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addBankAccountForCustomer = async (paymentMethodId) => {
  try {
    const { data } = await axios.post(api.addCustomerBankAccount, {
      paymentMethodId,
    });
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deletePaymentMethod = async (paymentMethodId) => {
  try {
    const { data } = await axios.delete(
      `${api.deletePaymentMethod}/${paymentMethodId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const setDefaultPaymentMethod = async (paymentMethodId) => {
  try {
    const { data } = await axios.post(api.setDefaultPaymentMethod, {
      paymentMethodId,
    });
    return data;
  } catch (error) {
    return handleError(error);
  }
};
