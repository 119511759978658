import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import env from "../../env";
import { makeBankDefault } from "../../endpoints/stripe.service";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { deleteBankThunk, fetchBanks } from "../../store/actions/bank.action";
import DeleteIcon from "../../assets/svg/DeleteIcon";
import FooterImage from "../../assets/images/stripe.png";
import { BankForm } from "../../components/BankAccountForm/BankForm";

const stripePromise = loadStripe(env.publishableKey);

const PaymentPage = () => {
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.bank.banks);
  const loading = useSelector((state) => state.bank.loading);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(fetchBanks())
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading, showForm]);

  const setDefault = (bankId) => {
    setIsLoading(true);
    makeBankDefault(bankId)
      .then((response) => {
        if (response.success)
          enqueueSnackbar(response.data.message, { variant: "success" });
      })
      .catch((err) => enqueueSnackbar(err, { variant: "error" }))
      .finally(() => setIsLoading(false));
  };

  const handleDelete = (bankId) => {
    dispatch(deleteBankThunk(bankId))
      .then((response) =>
        enqueueSnackbar("Bank deleted successfully", { variant: "success" })
      )
      .catch((error) =>
        enqueueSnackbar(error.message || "Error deleting bank", {
          variant: "error",
        })
      );
  };

  const handleCancel = (value) => {
    setShowForm(!value);
  };

  return (
    <div className="flex w-full justify-center items-center overflow-y-scroll bg-white">
      {isLoading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div key={key} className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : showForm ? (
        <Elements stripe={stripePromise}>
          <BankForm sendCancel={handleCancel} type="referral" />
        </Elements>
      ) : banks.length == 0 ? (
        <div className=" bg-white w-full  rounded-lg flex justify-center ">
          <div className="flex flex-col justify-center items-center">
            <button className="  rounded-md border-2 border-indigo-400 px-2 py-0 mb-11 flex justify-between items-center">
              <span className="text-xs text-indigo-500 font-bold mr-2">
                {" "}
                Powered by
              </span>{" "}
              <span className="text-2xl text-indigo-500 font-[1000]">
                stripe
              </span>
            </button>
            <div className="text-base/8 font-semibold flex flex-col justify-center items-center mb-11">
              <p>You currently do not have any bank accounts.</p>
              <p>Please add bank accounts to receive payments.</p>
            </div>
            <div className="flex gap-4 text-sm font-medium mb-11">
              <button
                className="bg-[#F1F5F9] text-primary text-base font-semibold rounded-md border-none px-6 py-3 cursor-pointer flex-1 text-center whitespace-nowrap"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Add a bank account
              </button>
            </div>
            <div className="flex">
              <img src={FooterImage} alt="footer image" width={450} />
            </div>
          </div>
        </div>
      ) : (
        <div className=" bg-white w-full  rounded-lg flex justify-center h-full ">
          <div className="flex flex-wrap justify-between items-start px-6 mr-10">
            <div className="w-full border rounded-lg px-4 py-4 mt-8">
              <div className="flex items-center justify-between mt-3 px-2">
                <h1 className="font-semibold text-lg">Manage Bank Accounts</h1>
                <button
                  className="border rounded-3xl border-black px-6 py-2 font-semibold text-sm"
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  Add a new account
                </button>
              </div>
              <div className="flex flex-wrap w-full px-2 pl-8">
                <table className="w-full table-fixed mt-8">
                  <thead>
                    <tr className="text-left">
                      <th className="text-sm font-light text-gray-500 w-1/4">
                        Account number
                      </th>
                      <th className="text-sm font-light text-gray-500 w-1/4">
                        Account holder
                      </th>
                      <th className="text-sm font-light text-gray-500 w-1/4">
                        Bank Name
                      </th>
                      <th className="w-1/4"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {banks?.map((bank) => {
                      return (
                        <tr key={bank.id} className="text-primary pt-10 mb-5">
                          <td className="w-1/4 text-sm  pb-4 border-b border-gray-200">
                            <div className="flex items-center gap-x-2 flex-1">
                              <span> **** </span> <span> **** </span>
                              <span> **** </span>
                              <span>{bank.last4}</span>
                            </div>
                          </td>
                          <td className="w-1/4 text-sm  pb-4 border-b border-gray-200">
                            {bank.account_holder_name}
                          </td>
                          <td className="w-1/6 text-sm pb-4 border-b border-gray-200">
                            {bank.bank_name}
                          </td>
                          <td className="w-1/3 text-right pb-4 pl-10 items-center">
                            <div className="inline-flex gap-2 justify-end items-center w-full">
                              {bank.default_for_currency ? (
                                <button className="border rounded-md bg-gray-100 px-4 py-2 text-xs text-gray-500 w-full">
                                  Default Bank
                                </button>
                              ) : (
                                <>
                                  <button
                                    className="border rounded-md bg-white-100 px-4 py-2 text-xs text-gray-500 w-full"
                                    onClick={() => setDefault(bank.id)}
                                  >
                                    Set as default
                                  </button>
                                  <button
                                    className="border rounded-md p-2"
                                    onClick={() => handleDelete(bank.id)}
                                  >
                                    <DeleteIcon className="w-5 h-5" />
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
