// auth token, logged
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const LOGIN_TOKEN = "LOGIN_TOKEN";
export const LOGIN_USER = "LOGIN_USER";
export const AUTH_USER_UPDATE = "AUTH_USER_UPDATE";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const SET_JOB_DETAIL_DATA = "SET_JOB_DETAIL_DATA";
export const SET_ASSESSMENT_DATA = "SET_ASSESSMENT_DATA";
export const SET_CANDIDATES_DATA = "SET_CANDIDATES_DATA";
export const SET_CANDIDATES_ASSESSMENT_DATA = "SET_CANDIDATES_ASSESSMENT_DATA";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const RESET_SEARCH_DATA = "RESET_SEARCH_DATA";
export const SET_UNSEEN_MESSAGE_COUNT = "SET_UNSEEN_MESSAGE_COUNT";
export const RESET_UNSEEN_MESSAGE_COUNT = "RESET_UNSEEN_MESSAGE_COUNT";
export const SET_CLIENT_SECRET = "SET_CLIENT_SECRET";
export const SET_CONNECTED_ACCOUNT_DATA = "SET_CONNECTED_ACCOUNT_DATA";

// Action types for fetching banks
export const FETCH_BANKS_REQUEST = "FETCH_BANKS_REQUEST";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAILURE = "FETCH_BANKS_FAILURE";
export const FETCH_BANK_BY_ID_SUCCESS = "FETCH_BANK_BY_ID_SUCCESS";
export const FETCH_BANK_BY_ID_FAILURE = "FETCH_BANK_BY_ID_FAILURE";

// Action types for deleting a bank
export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAILURE = "DELETE_BANK_FAILURE";

// Action types for fetching connected accounts
export const FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST =
  "FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST";
export const FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS =
  "FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS";
export const FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE =
  "FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE";
