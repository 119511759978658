import { useForm } from "react-hook-form";
import { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  addBankAccountForCustomer,
  addBankDetails,
} from "../../endpoints/stripe.service";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

export const BankForm = ({ sendCancel, type }) => {
  const [mandateAccepted, setMandateAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const { control } = useForm({
    mode: "all",
    defaultValues: {
      level: "",
    },
  });
  const selectStyle = {
    MozAppearance: "none",
    WebkitAppearance: "none",
    appearance: "none",
    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2032%2032%22%20stroke-width%3D%220%22%20stroke%3D%22%232e2e2e%22%20class%3D%22custom-arrow%22%3E%3Cpath%20d%3D%22M9.3335%2013.333L16.0002%2019.9997L22.6668%2013.333%22%20stroke%3D%22%232e2e2e%22%20stroke-opacity%3D%221%22%20stroke-width%3D%224%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20/%3E%3C/svg%3E')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right .7em center",
    backgroundSize: "16px auto",
    paddingRight: "2.5em",
  };

  const dobChange = (e) => {
    e.preventDefault();

    const dateStr = e.target.value; // Expecting format "YYYY-MM-DD"
    if (dateStr) {
      const [year, month, day] = dateStr.split("-");
      setDob({
        year,
        month,
        day,
      });
    }
    // if (dateStr) {
    //   setLocalAccountDetails((prev) => ({
    //     ...prev,
    //     year,
    //     month,
    //     day,
    //   }));
    // }
  };

  const handleCancel = () => {
    sendCancel(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    //  Validate that user has accepted the Bacs mandate
    if (!mandateAccepted) {
      setErrorMessage("You must accept the Bacs Direct Debit mandate.");
      return;
    }

    try {
      const formData = new FormData(e.target);
      console.log("formData", formData);
      console.log(
        "vaules",
        formData.get("country"),
        formData.get("shortCode"),
        formData.get("accountNumber")
      );

      setLoading(true);
      // handle for recruiter
      if (type === "recruiter") {
        //  Create a Bacs Direct Debit Payment Method
        const paymentMethodResult = await stripe.createPaymentMethod({
          type: "bacs_debit",
          bacs_debit: {
            // Remove non-digits if user enters hyphens or spaces
            sort_code: sortCode.replace(/\D/g, ""),
            account_number: accountNumber.replace(/\D/g, ""),
          },
          billing_details: {
            name: formData.get("accountHolderName"),
            email: formData.get("email"),
            phone: formData.get("phone"),
            address: {
              line1: formData.get("streetAddress"), // Dummy address line 1
              city: formData.get("city"), // Dummy city
              postal_code: formData.get("postalCode"), // Dummy postal code
              country: "GB", // Country code for United Kingdom
              state: formData.get("state"),
            },
          },
        });
        console.log("create payment method result", paymentMethodResult);
        // Check if Stripe returned an error
        if (paymentMethodResult.error) {
          setLoading(false);
          enqueueSnackbar(paymentMethodResult?.error?.message, {variant:'error'});
          throw new Error(paymentMethodResult.error.message);
        }
        //  Payment Method created successfully
        addBankAccountForCustomer(paymentMethodResult.paymentMethod.id)
          .then((response) => {
            console.log("add bank account response", response);
            if (response.success) {
              sendCancel(true);
              enqueueSnackbar("Bank account created successfully", {
                variant: "success",
              });
            }
          })
          .catch((error) =>
            enqueueSnackbar(error || "Something went wrong", {
              variant: "error",
            })
          )
          .finally(() => setLoading(false));
      }

      // handle for referral
      if (type === "referral") {
        const { token, error } = await stripe.createToken("bank_account", {
          country: "GB",
          currency: "gbp",
          account_holder_name: formData.get("accountHolderName"),
          account_holder_type: "individual",
          routing_number: sortCode,
          account_number: accountNumber,
        });

        if (error) {
          setLoading(false);
          enqueueSnackbar(error?.message, {
            variant: "error",
          });
          console.log("error", error);
          return error;
        }

        // // add bank details to stripe account
        addBankDetails(token.id)
          .then((response) => {
            if (response.success) {
              sendCancel(true);
              enqueueSnackbar("bank account added successfully", {
                variant: "success",
              });
            }
          })
          .catch((error) => enqueueSnackbar(error, { variant: "error" }))
          .finally(() => setLoading(false));
      }
    } catch (err) {
      console.log("catch error", err);
      setErrorMessage(
        err.message || "An error occurred while creating payment method."
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-center h-max"
    >
      <h1 className="mb-4 text-xl font-semibold text-center">
        Add your Bank Account
      </h1>
      <div className="rounded-t-md border border-gray-200 px-14 pb-4  pt-4">
        <div>
          <p className="text-sm text-gray-500 font-normal mb-2">
            Enter your bank's registered address
          </p>
          {type === "recruiter" ? (
            <div>
              <input
                type="text"
                placeholder="Street address"
                name="streetAddress"
                className="rounded-lg px-4 py-3 border w-full mb-3"
              />
              <div className="flex mb-2">
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  className="rounded-lg px-4 py-3 border w-full mr-3"
                />
                <input
                  type="text"
                  placeholder="State/Province"
                  name="state"
                  className="rounded-lg px-4 py-3 border w-full"
                />
              </div>
              <div className="flex mb-3">
                <input
                  type="text"
                  placeholder="Country"
                  name="country"
                  className="rounded-lg px-4 py-3 border w-full mr-3"
                />
                <input
                  type="text"
                  placeholder="Postal code"
                  name="postalCode"
                  className="rounded-lg px-4 py-3 border w-full "
                />
              </div>
              <div className="flex mb-3">
                <input
                  placeholder="Phone number"
                  name="phone"
                  className="rounded-lg px-4 py-3 border w-full "
                  type="tel"
                  inputMode="tel"
                  value={phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow + only at start, then numbers only
                    const formattedValue = value.startsWith("+")
                      ? "+" + value.slice(1).replace(/[^\d]/g, "")
                      : value.replace(/[^\d]/g, "");
                    setPhone(formattedValue);
                  }}
                  pattern="^\+?[0-9]*$"
                  maxLength={16}
                />
              </div>
              <div className="flex mb-3">
                <input
                  type="email"
                  placeholder="Email address"
                  name="email"
                  className="rounded-lg px-4 py-3 border w-full "
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="rounded-b-md border-x border-b border-gray-200 px-14 pb-4 pt-6">
        <div className="flex mb-3">
          <input
            type="text"
            placeholder="Account holder name"
            name="accountHolderName"
            className="rounded-lg px-4 py-3 border w-full "
          />
        </div>
        <div className="flex mb-3">
          <input
            type="text"
            placeholder="Account number"
            name="accountNumber"
            className="rounded-lg px-4 py-3 border w-full "
            inputMode="numeric"
            pattern="[0-9]*"
            value={accountNumber}
            onChange={(e) => {
              const formattedValue = e.target.value.replace(/[^0-9]/g, "");
              setAccountNumber(formattedValue);
            }}
          />
        </div>
        <div className="flex mb-3">
          <input
            type="text"
            placeholder="Short code"
            name="sortCode"
            className="rounded-lg px-4 py-3 border w-full  mr-3"
            inputMode="numeric"
            pattern="[0-9]*"
            value={sortCode}
            onChange={(e) => {
              const formattedValue = e.target.value.replace(/[^0-9]/g, "");
              setSortCode(formattedValue);
            }}
          />
        </div>
        {type === "recruiter" ? (
          <div className="mb-2 ">
            <input
              type="checkbox"
              checked={mandateAccepted}
              name="mandidate"
              onChange={(e) => setMandateAccepted(e.target.checked)}
              id="bacs-mandate"
            />
            <label htmlFor="bacs-mandate" style={{ marginLeft: 8 }}>
              I authorize direct debits from my bank account according to Bacs
              rules.
            </label>
          </div>
        ) : (
          <div className="flex mb-2">
            <input
              type="checkbox"
              id="bacs-mandate"
              checked={mandateAccepted}
              name="mandidate"
              onChange={(e) => setMandateAccepted(e.target.checked)}
            />
            <p className="ml-2 text-sm">
              I agree with{" "}
              <span>
                <a
                  className="underline"
                  href="https://stripe.com/gb/legal/connect-account/recipient"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </span>{" "}
              and{" "}
              <span>
                <a className="underline" href="">
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        )}

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
      <div className="flex justify-center mt-8 px-14">
        <button
          className="rounded-lg border border-black font-medium  py-2 mr-3 flex-1 text-center whitespace-nowrap"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className={`flex items-center justify-center rounded-lg border bg-primary py-2 text-white font-semibold flex-1 text-center whitespace-nowrap `}
          disabled={loading}
        >
          {loading ? <LoadingAnimation /> : null}
          Add Account
        </button>
      </div>
    </form>
  );
};
