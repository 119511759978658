import {
  deleteBank,
  getAccountDetails,
  getBanksLists,
} from "../../endpoints/stripe.service";
import {
  DELETE_BANK_FAILURE,
  DELETE_BANK_REQUEST,
  DELETE_BANK_SUCCESS,
  FETCH_BANK_BY_ID_FAILURE,
  FETCH_BANKS_FAILURE,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE,
  FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST,
  FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS,
} from "./action.types";

export const fetchBanks = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BANKS_REQUEST });
    try {
      const response = await getBanksLists();
      if (response.success) {
        dispatch({ type: FETCH_BANKS_SUCCESS, payload: response.data.data });
      } else {
        dispatch({ type: FETCH_BANKS_FAILURE, payload: response.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_BANKS_FAILURE, payload: error.message });
    }
  };
};

// Thunk to delete the bank update locally
export const deleteBankThunk = (bankId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_BANK_REQUEST });
    try {
      const response = await deleteBank(bankId);
      if (response.success) {
        dispatch({ type: DELETE_BANK_SUCCESS, payload: bankId });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: DELETE_BANK_FAILURE,
          payload: response.data.message || "Failed to delete bank",
        });
        return Promise.reject(
          new Error(response.data?.message || "Failed to delete bank")
        );
      }
    } catch (error) {
      dispatch({
        type: DELETE_BANK_FAILURE,
        payload: error || "Something went wrong",
      });
      return Promise.reject(new Error(error || "Something went wrong"));
    }
  };
};

// Thunk to fetch bank details by id from the list
export const fetchBankById = (bankId) => {
  return async (dispatch, getState) => {
    try {
      // get the current lists of banks from redux store
      const { banks } = getState().bank;

      // find bank by it's id
      const bank = banks.find((bank) => bank.id === bankId);

      if (!bank) {
        throw new Error("Bank not found");
      }

      // Dispatch action with the found bank details
      dispatch({ type: "FETCH_BANK_BY_ID_SUCCESS", payload: bank });

      return Promise.resolve(bank);
    } catch (error) {
      dispatch({
        type: FETCH_BANK_BY_ID_FAILURE,
        payload: error || "Something went wrong",
      });
      return Promise.reject(new Error(error || "Something went wrong"));
    }
  };
};

// fetch connected account details
export const fetchConnectedAccountDetails = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST });
    try {
      const response = await getAccountDetails();
      if (response.success) {
        console.log("get account details action", response);
        dispatch({
          type: FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE,
          payload: response.data.message || "Failed to fetch account details ",
        });
        return Promise.reject(
          new Error(response.data?.message || "Failed to fetch account details")
        );
      }
    } catch (error) {
      dispatch({
        type: FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE,
        payload: error || "Something went wrong.",
      });
      return Promise, reject(new Error(error || "Something went wrong"));
    }
  };
};
