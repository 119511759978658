import {
  DELETE_BANK_FAILURE,
  DELETE_BANK_REQUEST,
  DELETE_BANK_SUCCESS,
  FETCH_BANK_BY_ID_FAILURE,
  FETCH_BANK_BY_ID_SUCCESS,
  FETCH_BANKS_FAILURE,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE,
  FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST,
  FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS,
} from "../actions/action.types";

const initialState = {
  banks: [],
  loading: false,
  error: null,
};

const bankReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BANKS_REQUEST:
    case DELETE_BANK_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_BANKS_SUCCESS:
      return { ...state, loading: false, banks: payload };
    case FETCH_BANK_BY_ID_SUCCESS:
      return { ...state, selectedBank: payload };
    case FETCH_BANK_BY_ID_FAILURE:
      return { ...state, error: payload };
    case FETCH_BANKS_FAILURE:
      return { ...state, loading: false, error: payload };
    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: state.banks.filter((bank) => bank.id !== action.payload),
        error: null,
      };
    case DELETE_BANK_FAILURE:
      return { ...state, loading: false, error: payload };
    case FETCH_CONNECTED_ACCOUNT_DETAILS_REQUEST:
    case FETCH_CONNECTED_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, accountDetails: payload };
    case FETCH_CONNECTED_ACCOUNT_DETAILS_FAILURE:
      return { ...state, error: payload };
    default:
      return state;
  }
};

export default bankReducer;
