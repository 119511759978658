import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { addCardPaymentMethod } from "../../../endpoints/stripe.service";
import { useForm } from "react-hook-form";
import CustomInput from "../../CustomInput/CustomInput";
import { useState } from "react";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";

const CardForm = ({ sendCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleAddCard = async (data) => {
    const payloadData = data;
    payloadData.country = "GB";
    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardNumberElement);
    setIsLoading(true);
    const { error, token } = await stripe.createToken(cardElement, payloadData);
    console.log("error", error);
    console.log("token", token);
    if (error) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: "error" });
      console.log("error adding card :=> ", error);
    } else {
      addCardPaymentMethod(token.id)
        .then((response) => {
          if (response.success) {
            sendCancel(true);
            enqueueSnackbar("Payment method added successfully", {
              variant: "success",
            });
          }
        })
        .catch((error) => enqueueSnackbar(error, { variant: "error" }))
        .finally(() => setIsLoading(false));
    }
  };

  const handleCancel = () => {
    sendCancel(true);
  };
  return (
    <form onSubmit={handleSubmit(handleAddCard)} className="">
      {/* <CardElement /> */}
      <h1 className="mb-4 text-xl font-semibold text-center">
        Add your Credit/Debit card
      </h1>
      <div className="border border-gray-200 px-14 pb-6 pt-4">
        <div>
          <p className="text-sm text-gray-500 font-normal mb-2">
            Enter your card's registered address
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <CustomInput
              name="address_line1"
              placeholder="Street address"
              rules={{
                required: "This field is required.",
              }}
              textStyle="text-base"
              control={control}
              errors={errors}
            />
            <div className="flex gap-3">
              <CustomInput
                name="address_city"
                placeholder="City"
                rules={{
                  required: "This field is required.",
                }}
                textStyle="text-base"
                control={control}
                errors={errors}
              />
              <CustomInput
                name="address_state"
                placeholder="State/Province"
                rules={{
                  required: "This field is required.",
                }}
                textStyle="text-base"
                control={control}
                errors={errors}
              />
            </div>
            <div className="flex gap-3">
              <CustomInput
                name="address_country"
                placeholder="Country"
                rules={{
                  required: "This field is required.",
                }}
                textStyle="text-base"
                control={control}
                errors={errors}
              />
              <CustomInput
                name="address_zip"
                placeholder="Postal Code"
                rules={{
                  required: "This field is required.",
                }}
                textStyle="text-base"
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="relative rounded-lg px-4 py-4 border w-full">
            <CardNumberElement id="card-number" />
          </div>
          <div>
            <CustomInput
              name="name"
              placeholder="Name on card"
              rules={{
                required: "This field is required.",
              }}
              textStyle="text-base"
              control={control}
              errors={errors}
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="rounded-lg px-4 py-4 border w-full mb-3 ">
              <CardExpiryElement id="card-expiry" />
            </div>
            <div className="rounded-lg px-4 py-4 border w-full mb-3 ">
              <CardCvcElement id="card-cvc" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8 px-14">
        <button
          className="rounded-lg border border-black font-medium  py-2 mr-3 flex-1 text-center whitespace-nowrap"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="flex items-center justify-center rounded-lg border bg-primary py-2 text-white font-semibold flex-1 text-center whitespace-nowrap"
        >
          {isLoading ? <LoadingAnimation /> : null}
          Add Card
        </button>
      </div>
    </form>
  );
};

export default CardForm;
