import {
  SET_CLIENT_SECRET,
  SET_CONNECTED_ACCOUNT_DATA,
} from "../actions/action.types";

const initialState = {
  clientSecret: "",
  accountDetails: [],
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CLIENT_SECRET:
      return { ...state, clientSecret: payload };
    case SET_CONNECTED_ACCOUNT_DATA:
      return { ...state, accountDetails: payload };
    default:
      return state;
  }
};

export default paymentReducer;
