import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

export const getReferralMatchedJobs = async (candidateId) => {
  try {
    const { data } = await axios.get(
      api.referralMatchedJobs + `?candidateId=${candidateId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const verifyReferralInvite = async (email) => {
  try {
    const { data } = await axios.get(api.verifyReferralInvite + `/${email}`);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const inviteNewReferral = async (params) => {
  try {
    const { referralEmail, invitedBy, referralAlreadyExists } = params;

    const requestBody = {
      invitedBy,
      referralAlreadyExists,
    };

    if (!referralAlreadyExists) {
      requestBody.referralEmail = referralEmail;
    }

    const { data } = await axios.post(api.inviteReferral, requestBody);
    return data && data.success ? true : false;
  } catch (err) {
    return handleError(err);
  }
};

export const contactMatchesJob = async () => {
  try {
    const { data } = await axios.get(api.contactMatchesJob);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const contactMatchesSkills = async (candidateId, jobId) => {
  try {
    const { data } = await axios.get(
      api.contactMatchesSkills + `/${candidateId}/${jobId}`
    );

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getReferralTrackers = async (candidateId) => {
  try {
    const { data } = await axios.get(
      `${api.getReferralTrackers}?candidateId=${candidateId}`
    );

    return data;
  } catch (err) {
    return handleError(err);
  }
};
