import React, { useEffect, useState } from "react";
import {
  createPaymentIntent,
  getReferrals,
} from "../../endpoints/stripe.service";
import { useDispatch } from "react-redux";
import { setClientSecret } from "../../store/actions/payment.action";
import { useNavigate } from "react-router-dom";

const ReferralPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(referrals?.length === 0);
  //   const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    getReferrals()
      .then((response) => {
        if (response.success) setReferrals(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handlePayment = (stripeAccountId) => {
    console.log("stripeAccountId", stripeAccountId);
    createPaymentIntent(stripeAccountId, 50000).then((response) => {
      console.log("response", response);
      if (response.success) {
        dispatch(setClientSecret(response.data.clientSecret));
        navigation("/checkout");
        // setClientSecret(response.data.clientSecret);
      }
    });
  };
  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : referrals.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">
            No Referral with stripe's connected account found
          </p>
        </div>
      ) : (
        <div className="flex flex-col mt-4 gap-2">
          <table className="table-auto mt-3 w-full">
            <thead className="border-0">
              <tr>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  First Name
                </th>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  Last Name
                </th>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  Email Address
                </th>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((referral) => {
                return (
                  <tr key={referral?._id}>
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      {referral.firstName}
                    </td>
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      {referral.lastName}
                    </td>
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      {referral.email}
                    </td>
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      <button
                        className="rounded-md bg-[#ff9800] font-semibold text-white py-2 px-8"
                        onClick={() => handlePayment(referral.stripeAccountId)}
                      >
                        Pay
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReferralPage;
