import { SET_CLIENT_SECRET, SET_CONNECTED_ACCOUNT_DATA } from "./action.types";

export const setClientSecret = (payload) => ({
  type: SET_CLIENT_SECRET,
  payload,
});

export const setConnectedAccountDetails = (payload) => ({
  type: SET_CONNECTED_ACCOUNT_DATA,
  payload,
});
