import React, { useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { PaymentElement } from "@stripe/react-stripe-js";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // stripe hasn't yet loaded
      return;
    }

    console.log("elements", elements);

    const { error } = await stripe.confirmPayment({
      // 'Element' instance that was used to create the payment element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/referral",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      navigate("/referral");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        className="rounded-md bg-[#ff9800] font-semibold text-white py-2 px-8 mt-5"
        disabled={!stripe}
      >
        Submit
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
