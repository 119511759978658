import React, { useEffect, useRef, useState } from "react";
import {
  getBalanceInfo,
  withDrawBalance,
} from "../../endpoints/stripe.service";
import { useSnackbar } from "notistack";

const BalancePage = () => {
  const [balance, setBalance] = useState(0);
  const [withdrawalAmount, setWithDrawalAmount] = useState(0);
  const [loading, setLoading] = useState(balance?.length === 0);
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef(null);

  useEffect(() => {
    getBalanceInfo()
      .then((response) => {
        if (response.success) {
          let instantAmount = response.data.instant_available[0].amount;
          if (instantAmount > 0) instantAmount /= 100;
          setBalance(instantAmount);
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() => setLoading(false));
  }, [withdrawalAmount]);

  const handleWithdraw = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let amount = formData.get("amount");
    console.log("amount", amount);
    amount *= 100;
    console.log("amount", amount);
    withDrawBalance(amount)
      .then((response) => {
        console.log("withdrawl response", response);
        if (response.success) {
          setWithDrawalAmount(response.data.amount / 100);
          inputRef.current.value = "";
          enqueueSnackbar("Withdrawal requested successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log("withdrawl error ", err);
        enqueueSnackbar(err, { variant: "error" });
      });
  };
  return (
    <div className="flex flex-col flex-grow ">
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center ">
          <div className="mb-6">
            <p className="text-gray-700">balance info : £{balance} </p>
          </div>

          <form
            onSubmit={handleWithdraw}
            className="flex flex-col justify-center items-center mb-4"
          >
            <label htmlFor="amount" className="text-gray-700 mb-1">
              {" "}
              Amount
            </label>
            <input
              ref={inputRef}
              type="number"
              name="amount"
              id="amount"
              className="border border-gray-300 rounded-md py-1"
            />

            <button
              className="rounded-lg px-6 py-1 text-white bg-green-600 mt-4"
              type="submit"
            >
              Withdraw
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default BalancePage;
