import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import env from "../../env";
import CardForm from "../../components/Cards/Card/CardForm";
import {
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
} from "../../endpoints/stripe.service";
import { useSnackbar } from "notistack";

import FooterImage from "../../assets/images/stripe.png";

import DeleteIcon from "../../assets/svg/DeleteIcon";
import VisaCardImg from "../../assets/images/Visa.svg";
import MaestroCardImg from "../../assets/images/Maestro.png";
import AmericanExpressCardImg from "../../assets/images/AmericanExpress.png";
import MasterCardImg from "../../assets/images/MasterCard.png";
import { BankForm } from "../../components/BankAccountForm/BankForm";

const stripePromise = loadStripe(env.publishableKey);

const PaymentMethodPage = () => {
  const [cards, setCards] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(
    cards?.length === 0 || banks?.length === 0
  );
  const [cardsLoading, setCardsLoading] = useState(true);
  const [banksLoading, setBanksLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [showForm, setShowForm] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);

  useEffect(() => {
    getPaymentMethods("card")
      .then((response) => {
        console.log("payment methods response", response);
        if (response.success) {
          setCards(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => setCardsLoading(false));
    getPaymentMethods("bacs_debit")
      .then((response) => {
        console.log("payment methods response", response);
        if (response.success) {
          setBanks(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => setBanksLoading(false));
  }, [showForm]);

  const handleDelete = (id) => {
    deletePaymentMethod(id)
      .then((response) => {
        if (response.success) {
          enqueueSnackbar(response.data.message || "Deleted successfully.", {
            variant: "success",
          });
          // Refetch the lists of cards and banks
          setLoading(true);
          Promise.all([
            getPaymentMethods("card"),
            getPaymentMethods("bacs_debit"),
          ])
            .then(([cardResponse, bankResponse]) => {
              if (cardResponse.success) {
                setCards(cardResponse.data.data);
              }
              if (bankResponse.success) {
                setBanks(bankResponse.data.data);
              }
            })
            .catch((error) => {
              enqueueSnackbar(error || "Error fetching payment methods", {
                variant: "error",
              });
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((err) =>
        enqueueSnackbar(err || "Error deleting", { variant: "error" })
      );
  };

  const handleAddCard = () => {
    setShowForm(true);
    setShowCardForm(true);
  };

  const handelAddBank = () => {
    setShowCardForm(false);
    setShowForm(true);
  };

  const handleCancel = (value) => {
    setShowForm(!value);
  };

  const setDefault = (id, type) => {
    setDefaultPaymentMethod(id)
      .then((response) => {
        if (response.success) {
          enqueueSnackbar(
            `${type} set to default payment method successfully.`,
            {
              variant: "success",
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error ||
            `Error occurred while setting ${type.toLowerCase()} as default payment method.`,
          { variant: "error" }
        );
      });
  };

  return (
    <div className="flex w-full justify-center h-full bg-white p-8 flex-grow rounded-lg">
      {showForm ? (
        <div className="bg-white w-full rounded-lg flex justify-center overflow-y-scroll no-scrollbar">
          {showCardForm ? (
            <Elements stripe={stripePromise}>
              <CardForm sendCancel={handleCancel} />
            </Elements>
          ) : (
            <Elements stripe={stripePromise}>
              <BankForm sendCancel={handleCancel} type="recruiter" />
            </Elements>
          )}
        </div>
      ) : cards.length > 0 ||
        banks.length > 0 ||
        cardsLoading ||
        banksLoading ? (
        <div className="flex flex-col items-start w-full bg-white rounded-lg overflow-y-scroll no-scrollbar">
          <div className=" w-full border rounded-lg px-4 py-2  ">
            <div className="flex  items-center justify-between mt-3 px-2">
              <h1 className="mb-2 font-semibold text-lg">
                Manage Saved Cards{" "}
              </h1>
              <button
                className="border  border-black rounded-3xl px-6 py-2 font-semibold text-sm"
                onClick={() => {
                  setShowForm(true);
                  setShowCardForm(true);
                }}
              >
                Add a new Card
              </button>
            </div>
            <div className="flex flex-wrap w-full px-2">
              <table className="w-full border-separate border-spacing-y-3">
                <thead>
                  <tr className=" text-left">
                    <th className="text-sm font-light text-gray-500 w-1/4 pl-4">
                      Your saved cards
                    </th>
                    <th className="text-sm font-light text-gray-500 w-1/4">
                      Name on card
                    </th>
                    <th className="text-sm font-light text-gray-500 w-1/6">
                      Expires on
                    </th>
                    <th className="w-1/3"></th>
                  </tr>
                </thead>
                <tbody>
                  {cards?.map((card, cardIndex) => {
                    return (
                      <tr key={card.id} className="text-primary">
                        <td
                          className={`w-1/4 text-sm  py-1 ${
                            cardIndex !== 0 ? "border-t" : "border-0"
                          } border-gray-200`}
                        >
                          <div className="flex items-center pt-2 w-full">
                            <img
                              src={(function () {
                                switch (card.card.brand.toLowerCase()) {
                                  case "visa":
                                    return VisaCardImg;
                                  case "mastercard":
                                    return MasterCardImg;
                                  case "amex":
                                    return AmericanExpressCardImg;
                                  case "maestro":
                                    return MaestroCardImg;
                                  default:
                                    return VisaCardImg;
                                }
                              })()}
                              width={30}
                              className="mr-2"
                            />{" "}
                            <div className="flex items-center gap-x-2 flex-1">
                              <span> **** </span> <span> **** </span>
                              <span> **** </span>
                              <span>{card.card.last4}</span>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`w-1/4 text-sm py-1 ${
                            cardIndex !== 0 ? "border-t" : "border-0"
                          } border-gray-200`}
                        >
                          {card.billing_details.name}
                        </td>
                        <td
                          className={`w-1/6 text-sm py-1 ${
                            cardIndex !== 0 ? "border-t" : "border-0"
                          } border-gray-200`}
                        >
                          {card.card.exp_month}/{card.card.exp_year}
                        </td>
                        <td className="w-1/3 text-right py-1">
                          <div className="inline-flex gap-2 justify-end items-center">
                            {card.isDefault ? (
                              <button className="border rounded-md bg-gray-100 px-4 py-2 text-xs text-gray-500">
                                Default card
                              </button>
                            ) : (
                              <button
                                className="border rounded-md bg-white-100 px-4 py-2 text-xs text-gray-500"
                                onClick={() => setDefault(card.id, "Card")}
                              >
                                Set as default
                              </button>
                            )}
                            <button
                              className="border rounded-md p-2"
                              onClick={() => handleDelete(card.id)}
                            >
                              <DeleteIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {cardsLoading ? (
                <div className="flex flex-col gap-4 py-3">
                  {Array.from({ length: 5 }, (x, i) => i).map((key) => {
                    return (
                      <div className="grid grid-cols-3 items-center gap-28">
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                      </div>
                    );
                  })}
                </div>
              ) : !Array.isArray(cards) || !cards?.length ? (
                <div className="flex items-center justify-center w-full h-44">
                  <span className="font-medium text-sm text-light-black">
                    No any card details added.
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full border rounded-lg px-4 py-4 mt-8">
            <div className="flex items-center justify-between mt-3 px-2">
              <h1 className="font-semibold text-lg">Manage Bank Accounts</h1>
              <button
                className="border rounded-3xl border-black px-6 py-2 font-semibold text-sm"
                onClick={() => {
                  setShowForm(true);
                  setShowCardForm(false);
                }}
              >
                Add a new account
              </button>
            </div>
            <div className="flex flex-wrap w-full px-2 pl-8">
              <table className="w-full table-fixed mt-8">
                <thead>
                  <tr className="text-left">
                    <th className="text-sm font-light text-gray-500 w-1/4">
                      Account number
                    </th>
                    <th className="text-sm font-light text-gray-500 w-1/4">
                      Account holder
                    </th>
                    <th className="text-sm font-light text-gray-500 w-1/4">
                      Phone number
                    </th>
                    <th className="w-1/4"></th>
                  </tr>
                </thead>
                {Array.isArray(banks) && banks?.length ? (
                  <tbody>
                    {banks?.map((bank, bankIndex) => {
                      return (
                        <tr key={bank.id} className="text-primary pt-10 mb-5">
                          <td
                            className={`w-1/4 text-sm  py-2 ${
                              bankIndex !== 0 ? "border-t" : ""
                            } border-gray-200`}
                          >
                            <div className="flex items-center gap-x-2 flex-1">
                              <span> **** </span> <span> **** </span>
                              <span> **** </span>
                              <span>{bank.bacs_debit.last4}</span>
                            </div>
                          </td>
                          <td
                            className={`w-1/4 text-sm  py-2 ${
                              bankIndex !== 0 ? "border-t" : ""
                            } border-gray-200`}
                          >
                            {bank.billing_details.name}
                          </td>
                          <td
                            className={`w-1/6 text-sm py-2 ${
                              bankIndex !== 0 ? "border-t" : ""
                            } border-gray-200`}
                          >
                            {bank.billing_details.phone}
                          </td>
                          <td className="w-1/3 text-right py-2">
                            <div className="inline-flex gap-2 justify-end items-center">
                              {bank.isDefault ? (
                                <button className="border rounded-md bg-gray-100 px-4 py-2 text-xs text-gray-500">
                                  Default Bank
                                </button>
                              ) : (
                                <button
                                  className="border rounded-md bg-white-100 px-4 py-2 text-xs text-gray-500"
                                  onClick={() => setDefault(bank.id, "Card")}
                                >
                                  Set as default
                                </button>
                              )}
                              <button
                                className="border rounded-md p-2"
                                onClick={() => handleDelete(bank.id)}
                              >
                                <DeleteIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : null}
              </table>
              {banksLoading ? (
                <div className="flex flex-col gap-4 py-3">
                  {Array.from({ length: 5 }, (x, i) => i).map((key) => {
                    return (
                      <div className="grid grid-cols-3 items-center gap-28">
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                        <div className="w-28 h-2 rounded-md bg-gray-200"></div>
                      </div>
                    );
                  })}
                </div>
              ) : !Array.isArray(banks) || !banks?.length ? (
                <div className="flex items-center justify-center w-full h-44">
                  <span className="font-medium text-sm text-light-black">
                    No any bank details added.
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className=" bg-white w-full  rounded-lg flex justify-center ">
          <div className="flex flex-col justify-center items-center">
            <button className="  rounded-md border-2 border-indigo-400 px-2 py-0 mb-11 flex justify-between items-center">
              <span className="text-xs text-indigo-500 font-bold mr-2">
                {" "}
                Powered by
              </span>{" "}
              <span className="text-2xl text-indigo-500 font-[1000]">
                stripe
              </span>
            </button>
            {/* <img src={HeaderImage} alt="Powered by stripe" /> */}
            <div className="text-base/8 font-semibold flex flex-col justify-center items-center mb-11">
              <p>You currently do not have any payment methods.</p>
              <p>Please select a method to add.</p>
            </div>
            <div className="flex gap-4 text-sm font-medium mb-11">
              <button
                className="bg-[#F1F5F9] text-primary text-base font-semibold rounded-md border-none px-16 py-3 cursor-pointer  flex-1 text-center whitespace-nowrap"
                onClick={handleAddCard}
              >
                Add a card
              </button>
              <button
                className="bg-[#F1F5F9] text-primary text-base font-semibold rounded-md border-none px-6 py-3 cursor-pointer flex-1 text-center whitespace-nowrap"
                onClick={handelAddBank}
              >
                Add a bank account
              </button>
            </div>
            <div className="flex">
              <img src={FooterImage} alt="footer image" width={450} />
              {/* <BiSolidLock size={50} />
              <div className="mr-2">
                <p className="text-xs font-bold">Secure Payments</p>
                <button className="text-white border-none rounded-md bg-black px-3 py-1 ml-1 flex justify-between items-center">
                  <span className="text-xs font-semibold mr-2">Powered by</span>{" "}
                  <span className="text-2xl font-[1000]">stripe</span>
                </button>
              </div>
              <div>
                <p className="text-xs font-bold">
                  Safe and Secure SSL Encrypted
                </p>
                <div className="flex ml-2">
                  <img
                    src={MasterCardIcon}
                    alt="Master card"
                    width={40}
                    className="mr-4"
                  />
                  <img
                    src={VisaIcon}
                    alt="Visa card"
                    width={40}
                    className="mr-4"
                  />
                  <img src={AmexIcon} alt="American Express Card" width={50} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodPage;
