import { createCheckoutSession } from "../../endpoints/stripe.service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import env from "../../env";
import { useSelector } from "react-redux";
import CheckoutForm from "../../components/checkoutForm/CheckoutForm";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import { user } from "@nextui-org/theme";
import ProfileImg from "../../assets/images/profilepic.png";
import regexList from "../../utils/regexList";

const stripePromise = loadStripe(env.publishableKey);

const paymentMethods = [
  {
    label: "Credit/Debit card",
    value: 0,
    accountDetails: [
      {
        cardNo: "4855 **** **** ****",
        expDate: "04/24",
        user: "Vako Shvili",
      },
      {
        cardNo: "4855 **** **** ****",
        expDate: "04/24",
        user: "Vako Shvili",
      },
    ],
  },
  {
    label: "Net Banking",
    value: 1,
    accountDetails: [
      {
        accountNumber: "**** **** **** 4855",
        bankName: "Nation Wide",
        user: "Vako Shvili",
      },
      {
        accountNumber: "**** **** **** 4855",
        bankName: "Metro Bank",
        user: "Vako Shvili",
      },
    ],
  },
];

const RecipientForm = ({ control, errors }) => {
  return (
    <React.Fragment>
      <CustomInput
        label="Recipient's Name"
        name="recipientName"
        placeholder="Recipient's full name"
        rules={{
          required: "This field is required.",
        }}
        control={control}
        errors={errors}
      />
      <CustomInput
        label="Recipient's Email Address"
        name="recipientEmail"
        placeholder="Recipient's email address"
        rules={{
          required: "This field is required.",
          pattern: {
            value: regexList.email,
            message: "Please enter a valid email.",
          },
        }}
        control={control}
        errors={errors}
      />
    </React.Fragment>
  );
};

const CheckoutPage = () => {
  const { clientSecret } = useSelector((state) => state.payment);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      recipientName: "",
      recipientEmail: "",
    },
  });

  // Example price calculations (adjust based on your business logic)
  const amount = 500.0; // Total amount in pound
  const platformFee = amount * 0.2; // 20% platfrom fee
  const stripeFee = (amount + platformFee) * 0.029 + 0.3; // 2.9% + £0.30
  //   const applicationFee = amount * 0.05; // Example 5% application fee
  const total = amount + stripeFee + platformFee;
  //   const [checkoutSessionId, setCheckoutSessionId] = useState("");
  //   const [checkoutUrl, setCheckoutUrl] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const options = {
    clientSecret: clientSecret,
  };

  const handleCheckout = (data) => {
    console.log('form data',data)
    // createCheckoutSession("acct_1QtRmZCOdBGEmWLa", 50000).then((response) => {
    //   const session = response.data.session;
    //   if (response.success) {
    //     setCheckoutUrl(session.url);
    //     setCheckoutSessionId(session.id);
    //     window.location.href = session.url;
    //   }
    // });
  };
  return (
    <div className="w-full flex flex-row bg-white rounded-lg p-6 gap-6">
      <div className="w-[60%] flex flex-col gap-4">
        <h3 className="text-base font-medium">Recipient's Information</h3>
        <RecipientForm control={control} errors={errors} />
        <h3 className="text-base font-medium">Payment Method</h3>
        {paymentMethods?.map((paymentMethod) => {
          const isSelected = paymentMethod?.value === selectedPaymentMethod;
          return (
            <React.Fragment>
              <div
                key={paymentMethod?.value}
                onClick={() => setSelectedPaymentMethod(paymentMethod?.value)}
                className={`flex cursor-pointer items-center gap-4 border ${
                  isSelected
                    ? "border-secondary bg-[#FFF9EF]"
                    : "hover:bg-gray-50"
                } rounded-md px-4 py-2.5`}
              >
                <div
                  className={`h-4 w-4 rounded-full border ${
                    isSelected ? "border-secondary" : "border-light-black"
                  } flex justify-center items-center`}
                >
                  {isSelected ? (
                    <div className="h-2.5 w-2.5 bg-secondary rounded-full" />
                  ) : null}
                </div>
                <span className="font-medium text-xs text-primary">
                  {paymentMethod?.label}
                </span>
              </div>
              {isSelected ? (
                <div>
                  {paymentMethod?.accountDetails?.map(
                    (accountDetail, accountDetailIndex) => {
                      return (
                        <div
                          className={`py-3 ${
                            accountDetailIndex !== 0 ? "border-t" : ""
                          }`}
                        >
                          <div className="flex items-center justify-evenly cursor-pointer gap-10 rounded-md hover:bg-gray-100 py-2.5">
                            <span className="text-xs text-primary">
                              {accountDetail?.accountNumber ||
                                accountDetail?.cardNo}
                            </span>
                            <span className="text-xs text-primary">
                              {accountDetail?.expDate || accountDetail?.user}
                            </span>
                            <span className="text-xs text-primary">
                              {accountDetail?.bankName || accountDetail?.user}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex flex-col w-[40%] border-l p-5 gap-6">
        <h3 className="text-sm font-semibold">Checkout Details</h3>

        <div className="flex flex-col">
          <span className="font-medium text-xs text-light-black">
            Job ID: <span className="text-black">JO123-45</span>
          </span>
          <span className="font-medium text-xs text-light-black">
            Job Title: <span className="text-black">MERN Stack Developer</span>
          </span>
        </div>

        <div className="flex items-center justify-between border-b pb-5">
          <div className="flex flex-col gap-3">
            <span className="font-semibold text-xs">Candidate details</span>
            <div className="flex items-center gap-3">
              <img src={ProfileImg} className="w-9 h-9 rounded-full" />
              <div className="flex flex-col">
                <span className="text-xs">Sarah Cole</span>
                <span className="text-xs">AB12345</span>
              </div>
            </div>
          </div>
          <div className="w-px bg-gray-200 h-full" />
          <div className="flex flex-col gap-3">
            <span className="font-semibold text-xs">
              Referral member details
            </span>
            <div className="flex items-center gap-3">
              <img src={ProfileImg} className="w-9 h-9 rounded-full" />
              <div className="flex flex-col">
                <span className="text-xs">Darlene Robertson</span>
                <span className="text-xs">AB12345</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2.5">
          <span className="text-xs text-primary">Order summary</span>
          <div className="flex items-center justify-between">
            <span className="text-xs text-light-black">Subtotal</span>
            <span className="text-xs text-light-black">$61.97 USD</span>
          </div>
          <div className="flex items-center justify-between border-b pb-4">
            <span className="text-xs text-light-black">Coupon Discount</span>
            <span className="text-xs text-light-black">8%</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-xs text-primary">Total:</span>
            <span className="text-xs text-primary">$75.00 USD</span>
          </div>
        </div>

        <button
          onClick={handleSubmit(handleCheckout)}
          className="rounded-md bg-secondary text-white font-semibold py-2 text-sm"
        >
          Complete Payment
        </button>
      </div>
    </div>
  );

  // return (
  //   <div className="w-full flex flex-row bg-white rounded-lg p-6 overflow-y-scroll gap-8">
  //     <div className="w-1/2 flex flex-col gap-4">
  //       <h3 className="text-lg font-medium">Recipient's Information</h3>
  //       <RecipientForm control={control} errors={errors} />
  //       <h3 className="text-lg font-medium">Payment Method</h3>

  //       {/* <div className="space-y-2">
  //           <div className="flex justify-between">
  //             <span>Referral commission:</span>
  //             <span>£{amount.toFixed(2)}</span>
  //           </div>
  //           <div className="flex justify-between">
  //             <span>Stripe Fee (2.9% + £0.30):</span>
  //             <span>£{stripeFee.toFixed(2)}</span>
  //           </div>
  //           <div className="flex justify-between">
  //             <span>Platform Fee (20%):</span>
  //             <span>£{platformFee.toFixed(2)}</span>
  //           </div>
  //           <div className="flex justify-between pt-2 border-t border-gray-300">
  //             <span className="font-bold">Total:</span>
  //             <span className="font-bold">£ {total.toFixed(2)}</span>
  //           </div>
  //         </div> */}
  //     </div>
  //     <div className="w-1/2 mb-20">
  //       <Elements stripe={stripePromise} options={options}>
  //         <CheckoutForm />
  //         {/* <form className="h-screen" onSubmit={handleSubmit}>
  //       <PaymentElement></PaymentElement>
  //       <button className="text-black bg-secondary p-4" disabled={!stripe}>
  //         Submit
  //       </button>
  //       {errorMessage && <div>{errorMessage}</div>}
  //     </form> */}
  //       </Elements>
  //     </div>
  //   </div>
  // );
};

export default CheckoutPage;
